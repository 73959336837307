.MainPageMintDesc {
  /* position: absolute;
  width: 40%;
  top: 870px;
  left: 10%; */
}

.Divider {
  border-top: wheat;
  margin-block-start: 0.3em;
  margin-block-end: 0.3em;
  margin-left: 20px;
}

@media (max-width: 720px) {
  .MainPageMintDesc {
    /* width: 80%;
    left: 10%;
    top: 750px; */
  }
}

@media (min-width: 1600px) {
  .MainPageMintDesc {
    /* width: 40%; */
    /* left: 20%; */
  }
}
