/* cityglow, clouds, cross, farland, horizon, foreground */
.BackgroundContainer {
  /* height: 2000px; */
  position: relative;
  z-index: -10;
}

.Blender {
  width: 100%;
  height: 1600px;
  background-image: url('/src/image/web_blender.png');
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  z-index: 7;
}

.BackgroundTitle {
  width: 100%;
  height: 320px;
  background-image: url('/src/image/web_title_light.png');
  background-size: 60% auto;
  background-repeat: no-repeat;
  background-position: center;
  position: fixed;
  top: 50px;
  left: 0;
  z-index: 6;
}

.BackgroundTop {
  width: 100%;
  height: 1475px;
  background-image: url('/src/image/web_top.png');
  background-position-x: left;
  background-repeat: no-repeat;
  background-size: auto;
  position: absolute;
  left: 0;
  z-index: 5;
}

.BackgroundCross {
  width: 100%;
  height: 1129px;
  background-image: url('/src/image/web_cross.png');
  background-position-x: center;
  background-size: auto;
  left: 0;
  z-index: 4;
}

.BackgroundForeground {
  width: 100%;
  height: 600px;
  background-image: url('/src/image/web_foreground.png');
  background-position-x: left;
  background-size: auto;
  left: 0%;
  z-index: 2;
}

.BackgroundFarland {
  width: 100%;
  height: 500px;
  background-image: url('/src/image/web_farland.png');
  background-position-x: center;
  background-size: auto;
  left: 0;
  z-index: 1;
}

.BackgroundTown01 {
  width: 100%;
  height: 160px;
  background-image: url('/src/image/web_town01.png');
  background-position-x: center;
  background-size: auto;
  left: 0;
  z-index: 0;
}

.BackgroundTown02 {
  width: 100%;
  height: 244px;
  background-image: url('/src/image/web_town02.png');
  background-position-x: center;
  background-size: auto;
  left: 0;
  z-index: -1;
}

.BackgroundTown03 {
  width: 100%;
  height: 230px;
  background-image: url('/src/image/web_town03.png');
  background-position-x: center;
  background-size: auto;
  left: 0;
  z-index: -2;
}

.BackgroundTown04 {
  width: 100%;
  height: 548px;
  background-image: url('/src/image/web_town04.png');
  background-position-x: center;
  background-size: auto;
  left: 0;
  z-index: -3;
}

.BackgroundTown05 {
  width: 100%;
  height: 675px;
  background-image: url('/src/image/web_town05.png');
  background-position-x: center;
  background-size: auto;
  left: 0;
  z-index: -4;
}

.BackgroundTown06 {
  width: 100%;
  height: 339px;
  background-image: url('/src/image/web_town06.png');
  background-position-x: center;
  background-size: auto;
  left: 0;
  z-index: -5;
}

.BackgroundClouds {
  width: 100%;
  height: 248px;
  background-image: url('/src/image/web_clouds.png');
  background-position-x: center;
  background-size: auto;
  left: 0;
  z-index: -6;
}

.BackgroundHorizon {
  width: 100%;
  height: 600px;
  background-image: url('/src/image/web_horizon.png');
  background-position-x: center;
  background-size: auto;
  left: 0;
  z-index: -6;
}

.BackgroundCityglow {
  width: 100%;
  height: 1017px;
  background-image: url('/src/image/web_cityglow.png');
  background-position-x: center;
  background-size: auto;
  left: 0;
  z-index: -7;
}

.ControlGroup {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 60px;
  top: 900px;
  padding-bottom: 40px;
}

@media (max-width: 720px) {
  .BackgroundTop {
    width: 100%;
    height: 361px;
    background-image: url('/src/image/web_top_mobile.png');
    background-size: auto;
    left: 0;
    z-index: 5;
  }

  .BackgroundTitle {
    background-size: 80% auto;
    height: 160px;
  }

  .BackgroundForeground {
    background-image: url('/src/image/web_foreground_mobile.png');
    background-position-x: left;
  }

  .ControlGroup {
    top: 550px;
    width: 80%;
    padding-left: 10%;
    flex-direction: column;
  }
}

@media (min-width: 1600px) {
  .BackgroundForeground {
    background-position-x: center;
  }
}