.MainPageAction {
  display: flex;
  flex-direction: column;
  gap: 30px;
  /* position: absolute;
  width: 40%;
  top: 950px;
  left: 50%; */
}

@media (max-width: 720px) {
  .MainPageAction {
    /* width: 80%;
    left: 10%;
    top: 1150px; */
  }
}

@media (min-width: 1600px) {
  .MainPageAction {
    /* width: 40%; */
    /* left: 20%; */
  }
}