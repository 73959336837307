.BackgroundTitle {
  width: 80%;
  height: 256px;
  background-image: url('/src/image/web_title_light.png');
  background-size: 60% auto;
  background-repeat: no-repeat;
  background-position: center;
  position: fixed;
  top: 0px;
  left: 10%;
  z-index: 6;
  /* animation-name: switchImage;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 0.5s; */
  /* animation-direction: alternate; */
}

@media (max-width: 720px) {
  .BackgroundTitle {
    background-size: 80% auto;
    height: 160px;
    top: 0px;
  }
}