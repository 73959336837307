.MainPageShowcase {
  width: 90%;
  height: 400px;
  background-image: url('/src/image/banners/banner_0.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 450px;
  left: 5%;
  box-shadow: 0 0 18px 18px, 0 0 18px 12px inset;
  animation-name: switchImage;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 20s;
  animation-direction: alternate;
}

@media (max-width: 720px) {
  .MainPageShowcase {
    width: 100%;
    left: 0%;
    height: 200px;
    top: 350px;
  }
}

@media (min-width: 1600px) {
  .MainPageShowcase {
    background-size: auto;
  }
}

@keyframes switchImage {

  10% {
    background-image: url('/src/image/banners/banner_2.png');
  }

  20% {
    background-image: url('/src/image/banners/banner_3.png');
  }

  30% {
    background-image: url('/src/image/banners/banner_4.png');
  }

  40% {
    background-image: url('/src/image/banners/banner_5.png');
  }

  50% {
    background-image: url('/src/image/banners/banner_6.png');
  }

  60% {
    background-image: url('/src/image/banners/banner_7.png');
  }

  70% {
    background-image: url('/src/image/banners/banner_8.png');
  }

  80% {
    background-image: url('/src/image/banners/banner_9.png');
  }

  90% {
    background-image: url('/src/image/banners/banner_1.png');
  }

  100% {
    background-image: url('/src/image/banners/banner_0.png');
  }
}