.connectBtnContainer {
  display: flex;
  align-items: center;
}

.connectBtn {
  align-items: center;
  border-radius: 5px;
  padding: 10px 22px;
  font-weight: 600;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  font-family: blue-goblet;
  font-size: 1.5em;
  margin-bottom: 20px;
}

.connectBtn:hover {
  transition: all 0.2s ease-in-out;
  background: #fff;
  color: #808080;
}