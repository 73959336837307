.socialMediaBtn {
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.socialMediaContainer {
  /* position: absolute;
  top: 150px;
  width: 100%; */
}