.Text {
  font-family: 'blue-goblet', cursive;
  font-size: 1.8em;
  color: wheat;
  text-align: left;
  padding-left: 20px;
}

@media (max-width: 720px) {
  .Text {
    font-size: 1.5em;
  }
}
